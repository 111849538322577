
export class ValidationService {
    static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        const config = {
            required: 'This field is required',
            invalidCreditCard: 'Is invalid credit card number',
            invalidEmailAddress: 'Email must be a valid email address',
            invalidPassword: 'Invalid password. Password must be at least 6 characters long, and contain a number.',
            minlength: `Password must be at least ${validatorValue.requiredLength} characters`,
            maxlength: `The message is over ${validatorValue.requiredLength} characters`,
            min: `Age should be above or equal ${validatorValue.min}`,
            max: `Age should be below or equal ${validatorValue.max}`
        };

        return config[validatorName];
    }

    static emailValidator(control) {
        // RFC 2822 compliant regex
        if (!control.value) {
            return;
        }

        if (control.value?.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
            return null;
        } else {
            return { invalidEmailAddress: true };
        }
    }
}
