<app-header [navbarBrandRouterLink]="['/']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/dmd-logo.svg', width: 89, height: 25, alt: 'CoreUI Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/dmd-logo.svg', width: 30, height: 30, alt: 'CoreUI Logo'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]="'lg'">
  <!--<ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Dashboard</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Users</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Settings</a>
    </li>
  </ul>-->
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="fal fa-bell"></i><span class="badge badge-pill badge-danger">5</span></a>
    </li>
    <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="fal fa-envelope-open"></i><span
          class="badge badge-pill badge-info">5</span></a>
    </li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="false">
        <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" />
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Account</strong></div>
        <a class="dropdown-item" href="#"><i class="fal fa-cat-space"></i> Updates<span
            class="badge badge-info">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span
            class="badge badge-success">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span
            class="badge badge-danger">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-comments"></i> Comment<span
            class="badge badge-warning">42</span></a>
        <div class="dropdown-header text-center"><strong>Settings</strong></div>
        <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
        <a class="dropdown-item" href="#"><i class="fa fa-wrench"></i> Setting</a>
        <a class="dropdown-item" href="#"><i class="fa fa-usd"></i> Payments<span class="badge badge-dark">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-file"></i> Projects<span
            class="badge badge-primary">42</span></a>
        <div class="divider"></div>
        <a class="dropdown-item" href="#"><i class="fa fa-shield"></i> Lock account</a>
        <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</app-header>