import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import {
    AuthGuard,
    ApiService,
    JwtService,
    AuthService
} from './services';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ListErrorsComponent } from './layout/list-errors/list-errors.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ValidationService } from './services/validation.service';
import { ControlMessagesComponent } from './components/control-messages.component';
import { AppFooterModule, AppHeaderModule, AppSidebarModule, AppBreadcrumbModule, AppAsideModule } from '@coreui/angular';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { NoAuthGuard } from './services/no-auth-guard.service';

// localization module import
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserService } from './services/user.service';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { DudeApprovedGuard } from './services/dude-approved-guard.service';
import { DudeService } from './services/dude.service';
import { DudeProfileGuard } from './services/dude-profile-guard.service';
import { CommonHelper } from './helper/common.helper';
import { DudeStatusService } from './services/dude-status.service';
import { UserStatusService } from './services/user-status.service';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../../../assets/i18n/', '.json');
}


@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        ListErrorsComponent,
        ControlMessagesComponent,
        BreadcrumbsComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        AppBreadcrumbModule,
        AppAsideModule,
        TabsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate : false
        })
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        FooterComponent,
        HeaderComponent,
        ListErrorsComponent,
        BreadcrumbsComponent,
        FormsModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        ControlMessagesComponent,
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        AppAsideModule,
        AppBreadcrumbModule,
        TabsModule,
        TranslateModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
        ApiService,
        NoAuthGuard,
        AuthGuard,
        JwtService,
        AuthService,
        ValidationService,
        TranslateService,
        UserService,
        DudeService,
        DudeApprovedGuard,
        DudeProfileGuard,
        CommonHelper,
        DudeStatusService,
        UserStatusService
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],

})
export class CoreModule { }

