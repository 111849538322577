import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public title = 'Datemindude';
    constructor(public translate: TranslateService) {
        let currentLanguage = this.translate.getBrowserLang() || 'en';
        currentLanguage = 'en';
        this.translate.setDefaultLang(currentLanguage);
        this.translate.use(currentLanguage);
    }

    ngOnInit() {

    }
}

