import {Injectable} from '@angular/core';

@Injectable()
export class CommonHelper {
   constructor() {}

   public getDataDropdown(data) {
      const obj = [];
      let i = 1;

      // tslint:disable-next-line:only-arrow-functions
      data.forEach(function(modelValue) {
        for (const[ key, value ] of Object.entries(modelValue)) {
            obj.push({id: key, itemName: value});
        }

        i++;
      });

      return obj;
   }

}
