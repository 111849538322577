import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DudeService } from './dude.service';

@Injectable()
export class DudeProfileGuard implements CanActivate {

    constructor(
        private router: Router,
        private dudeService: DudeService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const uid = route.paramMap.get('id');
        const language = route.queryParamMap.get('lang');
        // tslint:disable-next-line:no-shadowed-variable
        return new Promise((resolve) => {
            this.dudeService.getDudeProfileDetail(uid).toPromise()
                .then((res) => {
                    resolve(true);
                })
                .catch(err => {
                    this.router.navigate(['/not-found'], { queryParams: { lang: language } });
                });
        });
    }
}
