import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  private language: string;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.language = params.lang;
    });
    if (this.language && this.language.toLocaleLowerCase() === 'da') {
      this.translate.use('da');
    }

  }

}
