import { Injectable } from '@angular/core';
import { ApiService } from '.';
import { Observable } from 'rxjs';
import { UserPaging } from 'src/app/models/user-paging.model';

@Injectable()

export class UserService {
  listUser: any;
  userPaging: UserPaging;
  constructor(
    private apiService: ApiService,
  ) { }

  GetUserList(item: UserPaging): Observable<any> {
    const isSorted = item.IsSorted ? 'desc' : 'asc';
    // tslint:disable-next-line:max-line-length
    return this.apiService.get(`users?pageSize=${item.PageSize}&pageNumber=${item.PageNumber}&field=${item.Field}&isSorted=${isSorted}&keyword=${item.Keyword}`);
  }

  getUserDetail(id: string): Observable<any> {
    return this.apiService.get(`users/${id}`);
  }

  updateUserDetail(id: string, object: object): Observable<any> {
    return this.apiService.patch(`users/${id}`, object);
  }

  deleteUser(id: string): Observable<any> {
    return this.apiService.delete(`users/${id}`);
  }

  checkRoleUser(object: object): Observable<any> {
    return this.apiService.post(`users/signin`, object);
  }

  getDudes(uid: string, paging: UserPaging): Observable<any> {
    const isSorted = paging.IsSorted ? 'desc' : 'asc';
    // tslint:disable-next-line:max-line-length
    return this.apiService.get(`dudes?uid=${uid}&pageSize=${paging.PageSize}&pageNumber=${paging.PageNumber}&field=${paging.Field}&isSorted=${isSorted}&keyword=${paging.Keyword}`);
  }
}
