<div class="app flex-row align-items-center notification-message">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="clearfix">
                    <h1 [translate]="'NOT_FOUND.HEADER'"></h1>
                    <h2 [translate]="'NOT_FOUND.HEADER_TEXT'"></h2>
                    <p [translate]="'NOT_FOUND.DESCRIPTION'"></p>
                </div>
            </div>
        </div>
    </div>
</div>
