import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './modules/core';
import { DudeApprovedComponent } from './modules/dude-approved/dude-approved.component';
import { DudeApprovedGuard } from './modules/core/services/dude-approved-guard.service';
import { DudeProfileComponent } from './modules/pages/dude-profile/dude-profile.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    },
    {
        path: 'not-found',
        loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule),
    },
    {
        path: 'dude-approve',
        loadChildren: () => import('./modules/dude-approved/dude-approved.module').then(m => m.DudeApprovedModule),
    },
    {
        path: 'dude-profile', // id= token >>uid male , uid female, create date
        loadChildren: () => import('./modules/pages/dude-profile/dude-profile.module').then(m => m.DudeProfileModule),
    },
    {
        path: 'dude-deleted-success',
        loadChildren: () => import('./modules/pages/dude-deleted-success/dude-deleted-success.module')
        .then(m => m.DudeDeletedSuccessModule),
    },
    {
        path: 'about-us',
        loadChildren: () => import('./modules/pages/about-us-page/about-us-page.module').then(m => m.AboutUsPageModule),
    },
    {
        path: 'faq',
        loadChildren: () => import('./modules/pages/faq-page/faq-page.module').then(m => m.FaqPageModule),
    },
    {
        path: 'download',
        loadChildren: () => import('./modules/pages/download-page/download-page.module').then(m => m.DownloadPageModule),
    },
    {
      path: '**',
      component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
