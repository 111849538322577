import { Injectable } from '@angular/core';
import { ApiService } from '.';
import { Observable } from 'rxjs';
import { UserPaging } from 'src/app/models/user-paging.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DudeService {

  constructor(
    private apiService: ApiService,
  ) { }

  approve(object: object): Observable<any> {
    return this.apiService.post(`dude/approve`, object);
  }

  dudePublish(object: object): Observable<any> {
    return this.apiService.post(`dude/dude-publish`, object);
  }

  getDudeProfileDetail(uid: string): Observable<any> {
    return this.apiService.get(`dude-profile/detail/${uid}`);
  }

  getDudeDetail(uid: string): Observable<any> {
    return this.apiService.get(`dudes/${uid}`);
  }

  requestToEdit(uid: string, object: object, lang: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('language', lang);
    return this.apiService.patch(`dude-profile/request-to-edit/${uid}`, object, { headers });
  }

  requestToDelete(uid: string): Observable<any> {
    return this.apiService.delete(`dude-profile/request-to-delete/${uid}`);
  }

  getUserDetail(id: string): Observable<any> {
    return this.apiService.get(`dude-profile/user/${id}`);
  }

  getCityList(): Observable<any> {
    return this.apiService.get(`common/city`);
  }

  getHeSeekList(): Observable<any> {
    return this.apiService.get(`common/heSeek`);
  }

  getInterestList(): Observable<any> {
    return this.apiService.get(`common/interests`);
  }

  getTagsList(): Observable<any> {
    return this.apiService.get(`common/tags`);
  }

  GetDudeList(item: UserPaging): Observable<any> {
    const isSorted = item.IsSorted ? 'desc' : 'asc';
    // tslint:disable-next-line:max-line-length
    return this.apiService.get(`dudes?pageSize=${item.PageSize}&pageNumber=${item.PageNumber}&field=${item.Field}&isSorted=${isSorted}&keyword=${item.Keyword}`);
  }

  updateDudeDetail(id: string, object: object): Observable<any> {
    return this.apiService.patch(`dudes/${id}`, object);
  }
}
