import { Injectable } from '@angular/core';


@Injectable()
export class JwtService {

    getToken(): string {
        return window.localStorage.jwtToken;
    }

    saveToken(token: string) {
        window.localStorage.jwtToken = token;
    }

    destroyToken() {
        window.localStorage.removeItem('jwtToken');
    }

    parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

}
