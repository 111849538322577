<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-last=last>
            <li class="breadcrumb-item" [ngClass]="{active: last}">
                <a *ngIf="!last" [routerLink]="breadcrumb.url">{{breadcrumb.label}}</a>
                <span *ngIf="last">{{breadcrumb.label}}</span>
            </li>
        </ng-template>
    </ol>
</nav>
