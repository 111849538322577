export enum DudeStatus {
    WaitingForApproval = 'waiting_for_approval',
    Approved = 'approved',
    WaitingForPublishing = 'Waiting for publishing',
    Published = 'published',
    Unpublished = 'unpublished',
    RequestForEditing = 'RequestForEditing',
    Deleted = 'deleted'
}

