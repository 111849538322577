import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from 'firebase';
import { promise } from 'protractor';
import { JwtService } from './jwt.service';
import { TranslateService } from '@ngx-translate/core';
import { IUser } from 'src/app/models/user.model';


@Injectable()
export class AuthService {
    userData: any;

    constructor(
        public afs: AngularFirestore,   // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        private jwtService: JwtService,
        private translate: TranslateService, // Inject Translation
    ) {
    }

    // Login
    SignIn(email: string, password: string): Promise<firebase.auth.UserCredential> {

        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    }

    // Reset Forggot password
    async ForgotPassword(passwordResetEmail: string) {
        const response = { isSuccess: false, status: '', message: '' };
        const statusSuccess = this.translate.instant('FORGOT_PASSWORD.STATUS_SUCCESS');
        const statusFail = this.translate.instant('FORGOT_PASSWORD.STATUS_FAIL');
        
        try {
            this.afAuth.auth.languageCode = 'da';
            await this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail);
            response.isSuccess = true;
            response.status = statusSuccess;

        } catch (error) {

            if (error.code === 'auth/user-not-found') {
                response.status = statusFail;
            } else {
                response.status = error.code;
                response.message = error.message;
            }
        }

        return response;
    }

    async SetUserData(user: User) {
        const userData: IUser = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified
        };
        this.jwtService.saveToken(await user.getIdToken());
        localStorage.setItem('user', JSON.stringify(userData));
    }

    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return (user !== null && user.emailVerified !== false) ? true : false;
    }

    getCurrentUser(): IUser {
        const user: IUser = JSON.parse(localStorage.getItem('user'));
        return user;
    }

    async SignOut() {
        await this.afAuth.auth.signOut();
        this.jwtService.destroyToken();
        localStorage.removeItem('user');
        this.router.navigate(['login']);
    }

}
