import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserStatus } from 'src/app/models/user-status.model';


@Injectable({
  providedIn: 'root'
})
export class UserStatusService {

  constructor(
    private translate: TranslateService,

  ) { }

  getStatusLocalize(status: UserStatus): string {
    switch (status) {
      case UserStatus.Published:
        return this.translate.instant('USER_STATUS.PUBLISHED');
      case UserStatus.Unpublished:
        return this.translate.instant('USER_STATUS.UNPUBLISHED');
    }


  }

}
