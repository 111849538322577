import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { JwtService } from './jwt.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private jwtService: JwtService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {

        if (this.jwtService.getToken() != null && this.jwtService.getToken() !== '' ) {
            const jsonJWT = this.jwtService.parseJwt(this.jwtService.getToken());
            if (Date.now() >= jsonJWT.exp * 1000) {
                this.authService.SignOut();
            } else {
                return true;
            }
        } else {
            this.authService.SignOut();
        }

    }
}
