import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DudeStatus } from 'src/app/models/dude-status.model';


@Injectable({
  providedIn: 'root'
})
export class DudeStatusService {

  constructor(
    private translate: TranslateService,

  ) { }

  getStatusLocalize(status: DudeStatus): string {
    switch (status) {
      case DudeStatus.Approved:
        return this.translate.instant('DUDE_STATUS.APPROVED');
      case DudeStatus.WaitingForApproval:
        return this.translate.instant('DUDE_STATUS.WAITING_FOR_APPROVAL');
      case DudeStatus.WaitingForPublishing:
        return this.translate.instant('DUDE_STATUS.WAITING_FOR_PUBLISHING');
      case DudeStatus.Published:
        return this.translate.instant('DUDE_STATUS.PUBLISHED');
      case DudeStatus.Unpublished:
        return this.translate.instant('DUDE_STATUS.UNPUBLISHED');
      case DudeStatus.RequestForEditing:
        return this.translate.instant('DUDE_STATUS.REQUEST_FOR_EDITING');
      case DudeStatus.Deleted:
        return this.translate.instant('DUDE_STATUS.DELETED');
      default:
        return '';
    }
  }

  getStatusStringLocalize(status: string, lang: string): string {
    this.translate.use(lang);
    switch (status.toLowerCase()) {
      case 'approved':
        return this.translate.instant('DUDE_STATUS.APPROVED');
      case 'waiting_for_approval':
        return this.translate.instant('DUDE_STATUS.WAITING_FOR_APPROVAL');
      case 'waiting for publishing':
        return this.translate.instant('DUDE_STATUS.WAITING_FOR_PUBLISHING');
      case 'published':
        return this.translate.instant('DUDE_STATUS.PUBLISHED');
      case 'unpublished':
        return this.translate.instant('DUDE_STATUS.UNPUBLISHED');
      case 'request to edit':
        return this.translate.instant('DUDE_STATUS.REQUEST_FOR_EDITING');
      case 'deleted':
        return this.translate.instant('DUDE_STATUS.DELETED');
      default:
        return '';
    }
  }

}
