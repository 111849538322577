import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';
import { JwtService } from './jwt.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { DudeService } from './dude.service';
import { DudeApproveLink } from 'src/app/models/dude-approve-link.model';
import { take } from 'rxjs/operators';
import { resolve } from 'dns';

@Injectable()
export class DudeApprovedGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService,
        private jwtService: JwtService,
        private dudeService: DudeService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {

        const token = route.paramMap.get('id');
        const language = route.queryParamMap.get('lang');
        const decode = decodeURIComponent(token);
        const objectStr = this.decode(token, environment.SECRET_KEY);
        // token is not exists
        if (objectStr === '') {
            this.router.navigate(['/not-found'], { queryParams: { lang: language } });
        }

        const obj = JSON.parse(objectStr);
        const createdDate = new Date(obj.created_date);

        createdDate.setMinutes(createdDate.getMinutes() + obj.minutes);
        const localTime = new Date();
        // timeout (more than 5 mins)
        if (createdDate.getTime() < localTime.getTime()) {
            this.router.navigate(['/not-found'], { queryParams: { lang: language } });
        }
        else {
            // tslint:disable-next-line:no-shadowed-variable
            return new Promise((resolve) => {
                this.dudeService.approve({ user_id: obj.user_id, uid: obj.dude_id }).toPromise()
                    .then((res) => {
                        resolve(true);
                    })
                    .catch(err => {
                        this.router.navigate(['/not-found'], { queryParams: { lang: language } });
                    });
            });
        }
        

    }


    private decode(cipherText, key) {
        const reb64 = CryptoJS.enc.Hex.parse(cipherText);
        const bytes = reb64.toString(CryptoJS.enc.Base64);
        const decrypt = CryptoJS.AES.decrypt(bytes, key);
        const plain = decrypt.toString(CryptoJS.enc.Utf8);
        return plain;
    }
}
